// import './admin';
import './baguette-box';
// import './googlemapsinfobox';
// import './loveform';
import './masonry';
// import './materialize';
// import './mmenu';
import './swiper-main';
// import './teich';

import './bootstrap';
import './navbar';
import './share';
import './sections'
import './read-counter';
import './isotope';
import {initForms} from "./form";
import './on-scroll';

initForms();