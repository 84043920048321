import $ from 'jquery';

$(() => {
  const shareButton = document.querySelector('.native-share-button');
  const clipboardButton = document.querySelector('.clipboard-button');
  const successMessage = clipboardButton?.dataset.successMessage;

  // Show Web Share button only if the API is available
  if (navigator.share) {
    shareButton?.classList.remove('d-none');

    shareButton?.addEventListener('click', () =>
      navigator.share({
        title: document.title,
        url: window.location.href,
      })
    );
  }

  // Clipboard API
  clipboardButton?.addEventListener('click', async () => {
    await navigator.clipboard.writeText(window.location.href);
    alert(successMessage);
  });
});
